<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Basic Information</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form v-if="show">
            <b-form-group label="Id:">
              <b-form-input type="text" v-model="patient.id" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Patient Group:">
              <b-form-input type="text" v-model="patient_group.name" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Clinicial Reference:">
              <b-form-input type="text" v-model="patient.clinical_reference"></b-form-input>
            </b-form-group>
            <b-form-group label="Username/Email:">
              <b-form-input type="email" v-model="patient.username_email" required></b-form-input>
            </b-form-group>
            <b-form-group label="Password:">
              <b-form-input type="password" v-model="new_password" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Phone:">
              <b-form-input type="text" v-model="patient.phone" placeholder="E.g. +447903001234"></b-form-input>
            </b-form-group>
            <b-form-group label="First Name:">
              <b-form-input type="text" v-model="patient.firstname" required></b-form-input>
            </b-form-group>
            <b-form-group label="Last Name:">
              <b-form-input type="text" v-model="patient.lastname" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Gender:">
              <b-form-select v-model="patient.gender" :options="gender_options" disabled></b-form-select>
            </b-form-group>
            <b-form-group label="Birthday:">
              <b-form-datepicker v-model="patient.birthday" disabled></b-form-datepicker>
            </b-form-group>
            <b-form-group label="Last Activity:">
              <b-form-input type="text" v-model="patient.last_login" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Concent:">
              <b-form-checkbox id="checkbox-1" v-model="patient.research_concent" name="checkbox-1">
                Research Concent
              </b-form-checkbox>
              <b-form-checkbox id="checkbox-2" v-model="patient.privacy_concent" name="checkbox-2">
                Privacy Concent
              </b-form-checkbox>
              <!-- <b-form-checkbox id="checkbox-3" v-model="patient.cookie_concent" name="checkbox-3">
                Cookie Concent
              </b-form-checkbox> -->
            </b-form-group>
            <!-- <b-form-group label="Meta Data:">
                <b-form-textarea
                  id="meta-data-json-textfield"
                  v-model="patient.meta_data"
                  :disabled="user.userTypeId != 4"
                  rows="4"
                  max-rows="6"
                ></b-form-textarea>
            </b-form-group> -->
            <b-button @click="onUpdatePatient" variant="primary">Save</b-button>
            <b-button v-b-modal.modal-delete-patient-confirmation variant="danger">Delete</b-button>
            <b-button @click="onResendAccessInfo" variant="info">Resend Access</b-button>
          </b-form>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Questionnaires" active>
            <b-card-text>
              <b-table hover bordered :items="patient_questionaire_answers" :fields="table_questionnaire_answered_fields" :sort-by.sync="q_sortBy" :sort-desc.sync="q_sortDesc" @row-clicked="(item, index, event) => questionnaireRowClickHandler(item)">
                <template #cell(questionnaire_type)="data">
                  {{ questionaire_types.filter(function(i) { return i.value === data.item.questionnaireId; })[0].text }}
                </template>
                <template #cell(status)="row">
                  <p>{{ statusCheck(row) }}</p>
                </template>
                <template #cell(actions)="row">
                  <b-button :disabled="statusCheck(row) != 'Empty'" variant="danger" size="sm" class="mr-2" @click="onDeleteQuestionnaireAnswer(row)">X</b-button>
                </template>
              </b-table>
              <b-form-group>
                <b-button v-b-modal.modal-auto-generate-questionaire variant="primary">Auto Generate</b-button>
                <b-button v-b-modal.modal-create-questionaire variant="success">Create New (Manual)</b-button>
              </b-form-group>
            </b-card-text>
          </b-tab>
          <b-tab title="Meta Configuration">
            <b-card-text>
              <b-form>

                <!-- <b-form-group 
                  label="Gen Password:"
                  description="Password.">
                  <b-form-input type="text" v-model="patient.meta_data.gen_password" disabled></b-form-input>
                </b-form-group> -->

                <b-form-group 
                  label="Default Record Length (Days):"
                  description="Default setting for number of days to record symptomes. ">
                  <b-form-input type="integer" v-model="patient.meta_data.default_tracking_length_days" disabled></b-form-input>
                </b-form-group>
                
                <b-form-group 
                  label="Start Date:"
                  description="Date when patient should start recording sympotomes.">
                  <b-form-datepicker v-model="patient.meta_data.start_tracking_datetime"></b-form-datepicker>
                </b-form-group>

                <b-form-group 
                  label="End Date:"
                  description="Date when patient should end recording sympotomes.">
                  <b-form-datepicker v-model="patient.meta_data.end_tracking_datetime" disabled></b-form-datepicker>
                </b-form-group>

                <b-form-group 
                  label="Edit Window (Hours)"
                  description="Questionnaires must be submitted/edited in X hours from date of syndrome.">
                  <b-form-input type="number" v-model="patient.meta_data.submit_edit_window_hours"></b-form-input>
                </b-form-group>

                <b-form-group 
                  label="Login Reminder Method"
                  description="Method that should be used to notify patient if inactive (no login) for X hours.">
                  <b-form-select v-model="patient.meta_data.login_reminder_method" :options="send_info_options"></b-form-select>
                </b-form-group>

                <b-form-group 
                  label="Login Reminder (Hours)"
                  description="Patient will be reminded to login if inactivity (no login) for X hours.">
                  <b-form-input type="number" v-model="patient.meta_data.login_remind_patient_hours"></b-form-input>
                </b-form-group>

                <b-form-group 
                  label="Login Reminder - Clinician(Hours)"
                  description="Clinician will be notified if patient is inactive (no login) for X hours.">
                  <b-form-input type="number" v-model="patient.meta_data.login_remind_clinician_hours"></b-form-input>
                </b-form-group>

                <b-form-group 
                  label="Last Login Reminder (Patient):"
                  description="Last time patient being reminded to login.">
                  <b-form-input type="text" v-model="patient.meta_data.last_login_remind_patient_datetime" disabled></b-form-input>
                </b-form-group>
                <b-form-group 
                  label="Last Login Reminder (Clinician):"
                  description="Last time clinician being reminded for inactivity.">
                  <b-form-input type="text" v-model="patient.meta_data.last_login_remind_clinician_datetime" disabled></b-form-input>
                </b-form-group>

                <b-form-group 
                  label="Clinician Notes:"
                  description="Notes and scribbles.">
                  <b-form-textarea v-model="patient.meta_data.clinician_notes"
                    placeholder="Clinician Notes ..." rows="3" max-rows="12" no-auto-shrink></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <b-button @click="onUpdatePatient" variant="primary">Save</b-button>
                </b-form-group>

                <!-- <p>{{patient.meta_data}}</p> -->

              </b-form>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <!-- begin::Modal -->
    <b-modal id="modal-view-questionnaire" title="View Questionnaire (Raw Data)">
      <p>{{ questionnaire_answer_on_display }}</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="onCloseViewQuestionnaire">
          Close
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-delete-patient-confirmation" title="Delete Patient">
      <h4>Are you sure you want to delete this patient?</h4>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelDeletePatient">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeletePatient">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-auto-generate-questionaire" title="Auto generate questionnaires">
      <h4>Are you sure you want to auto generate questionnaires based on study configuration?</h4>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelAutoGenerateQuestionaires">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="success" @click="onAutoGenerateQuestionaires">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-create-questionaire" title="Create Questionnaire">
      <b-form>
        <b-form-group label="Questionnaire Type">
          <b-form-select id="new-submission-typepicker" v-model="new_questionaire.type" :options="questionaire_types"></b-form-select>
        </b-form-group>
        <b-form-group label="Date of Symptomes">
          <b-form-datepicker id="new-submission-datepicker" v-model="new_questionaire.date"></b-form-datepicker>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelCreateQuestionaire">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onCreateQuestionaire">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-send-access-info" title="Send Access Information">
      <b-form>
        <b-form-group label="Send Method">
          <b-form-select id="new-send-access-info-method" v-model="send_access_info.method" :options="send_info_options"></b-form-select>
        </b-form-group>
        <!-- <b-form-group label="Send on which day?">
          <b-form-datepicker id="new-send-access-info-date" v-model="send_access_info.date" today-button locale="en"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Send at what time?">
          <b-form-timepicker id="new-send-access-info-time" v-model="send_access_info.time" now-button locale="en"></b-form-timepicker>
        </b-form-group> -->
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelSendAccessInfo">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="success" @click="onSendAccessInfo">
          Send
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "patient",
  data() {
    return {
      show: true,
      q_sortBy: 'symptoms_date',
      q_sortDesc: true,
      gender_options: [
        { text: "Select One", value: "" },
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" }
      ],
      send_info_options: [
        { text: "Via Email & Phone", value: "Both" },
        { text: "Via Email only", value: "Email" },
        { text: "Via Phone only", value: "Phone" },
        { text: "No Reminder", value: "None" }
      ],
      send_access_info: {
        method: "Both",
        date: null,
        time: null
      },
      patient: {
        id: "",
        username_email: "",
        firstname: "",
        lastname: "",
        Gender: "",
        birthday: "",
        research_concent: false,
        privacy_concent: false,
        cookie_concent: false,
        meta_data: {
          gen_password: "",
          clinician_notes: ""
        }
      },
      patient_group: null,
      patient_questionaire_answers: null,
      table_questionnaire_answered_fields: [
        { key: "id", label: "Id" },
        { key: "questionnaire_type", label: "Type"},
        { key: "symptoms_date", label: "Date of Symptoms"},
        { key: "status", label: "Status" },
        { key: "actions", label: "Action" },
      ],
      new_password: null,
      clinician_notes: "",
      new_questionaire: {
        type: null,
        date: null
      },
      questionaire_types: [
        { value: 1, text: "WE-PREVENT Daily questionaire"},
        { value: 2, text: "WE-PREVENT Weekly questionaire"},
        { value: 3, text: "WE-PREVENT Monthy questionaire (TRACK)"}
      ],
      questionnaire_answer_on_display: null
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },
  watch: {
    $route() {
      this.refresh();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Patients" }]);
    this.refresh();
  },
  methods: {
    refresh() {
      ApiService.get("/users/patient/" + this.$route.params.id)
      .then(({ data }) => {
        this.patient = data.data;
        this.patient_group = this.patient.groups[0];
        // console.log(this.patient);
      });

      ApiService.get("/questionnaires/patient/" + this.$route.params.id)
      .then(({ data }) => {
        this.patient_questionaire_answers = data.data;
      });
      // .catch(({ error }) => {
      //   console.log(error);
      // });
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    onDeletePatient() {
      alert("Do you want to delete pateint?");
      
    },
    statusCheck(row){
      if (row.item.answer_json == null) {
        return "Empty";
      } else {
        return "Answered";
      }
    },
    onCancelDeletePatient(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-patient-confirmation");
    },
    onCloseViewQuestionnaire(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-view-questionnaire");
    },
    questionnaireRowClickHandler(item) {
        this.questionnaire_answer_on_display = item.answer_json;
        this.$bvModal.show("modal-view-questionnaire");
    },
    onConfirmDeletePatient(){
      ApiService.delete("/users/patient/" + this.$route.params.id)
        .then(() => {
          // Success
          this.$router.push("/");
        })
        .catch(() => {
          // Failure
          alert("Error: Delete patient failed.");
        });
    },
    onCancelCreateQuestionaire(evt){
      evt.preventDefault();
      this.$bvModal.hide("modal-create-questionaire");
    },
    onCreateQuestionaire(){
      ApiService.post("/questionnaires/questionnaire_answer/new", {
        patient_id: this.$route.params.id,
        questionaire_id: this.new_questionaire.type,
        symptoms_date: this.new_questionaire.date,
      })
        .then(() => {
          // Success
          this.$bvModal.hide("modal-create-questionaire");
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error creating new questionnaire entry.");
        });
    },
    onDeleteQuestionnaireAnswer(row){
      if (row.item.answer_json == null) {
        ApiService.delete("/questionnaires/questionnaire_answer/" + row.item.id)
        .then(() => {
          // Success
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error: Delete questionnaire failed.");
        });
      }
    },
    onCancelAutoGenerateQuestionaires(evt){
      evt.preventDefault();
      this.$bvModal.hide("modal-auto-generate-questionaire");
    },
    onAutoGenerateQuestionaires(){
      ApiService.post("/questionnaires/questionnaire_answer/autogen", {
        patient_id: this.$route.params.id,
      })
        .then(() => {
          // Success
          this.$bvModal.hide("modal-auto-generate-questionaire");
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error auto generating new questionnaires.");
        });
    },
    onCancelSendAccessInfo(evt){
      evt.preventDefault();
      this.$bvModal.hide("modal-send-access-info");
    },
    onResendAccessInfo() {
      this.send_access_info = {
        method: "Both",
        date: null,
        time: null
      };
      this.$bvModal.show("modal-send-access-info");
    },
    onSendAccessInfo(){
      // console.log(this.send_access_info);
      // var send_datetime = new Date(this.send_access_info.date + "T" + this.send_access_info.time);
      // console.log(datetime);
      ApiService.post('/users/patient/' + this.$route.params.id + '/resend-access', {
          method: this.send_access_info.method,
          // send_at: send_datetime,
      })
        .then(() => {
          // Success
          alert("Success: Resent access information");
          this.$bvModal.hide("modal-send-access-info");
        })
        .catch(() => {
          // Failure
          alert("Error: Failed to resend access information");
        });
    },
    onUpdatePatient() {
      if(this.patient.meta_data.start_tracking_datetime != null){
        this.patient.meta_data.end_tracking_datetime = this.addDays(
          this.patient.meta_data.start_tracking_datetime,
          this.patient.meta_data.default_tracking_length_days);
          this.patient.meta_data.start_tracking_datetime = new Date(this.patient.meta_data.start_tracking_datetime);
      }
      ApiService.put("/users/patient/" + this.$route.params.id, {patient: this.patient, new_password: this.new_password} )
        .then(() => {
          // Success
          alert("Success: Patient information updated.");
          // console.log(data);
          this.new_password = "";
          this.refresh();
        })
        .catch(() => {
          // Failure
          this.new_password = "";
          alert("Error: Update patient information failed.");
        });
    }
  }
};
</script>
